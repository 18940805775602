.app {
    display: flex;
    height: 100vh;
    overflow: hidden;
  
    .sections {
      width: 100%;
      height: 100vh;
      position: relative;
      scroll-behavior: smooth;
      scroll-snap-type: y mandatory;
      overflow-y: scroll;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
  
      > * {
        scroll-snap-align: start;
      }
    }
  }
  
  html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  