.gow-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100vw - 200px); // Adjust according to your sidebar width
  margin-left: 200px;
  background-color: #fff;
  height: 100%;

  .gow-banner {
    width: 100%;
    height: 30vh; // Setting a dynamic height based on the view height
    overflow: hidden;

    .gow-banner-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .gow-body {
    display: flex;
    flex-direction: row; // Changed to row to align items side by side
    width: 100%;
    justify-content: space-between; // Distribute space between the elements

    .gow-info {
      width: 60%;
      padding: 20px;

      h1 {
        font-size: 1.5em; // Adjusted font size
      }

      .gow-description {
        font-size: 0.9em; // Adjusted font size
      }

      h2 {
        font-size: 1.2em; // Adjusted font size
      }

      ul {
        font-size: 0.8em; // Adjusted font size
        list-style: inside square; // Style for the list
        padding: 0;
      }
    }

    .project-media {
      width: 40%;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-end; // Align media elements to the right

      .gow-video {
        width: 100%;
        margin-top: 20px;
        position: relative;
        padding-bottom: 56.25%; // 16:9 aspect ratio
        height: 0;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }

      .gow-platform-logos {
        display: flex;
        flex-direction: column;
        align-items: center; // Center align for the logos

        a {
          width: 100%; // Make anchor take full width of the parent
          display: flex;
          justify-content: center; // Center the logo images

          img {
            width: 50%; // Make logos half the width of their container
            height: auto;
            margin-top: 20px; // Space between logos
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .gow-container {
    width: 100%;
    margin-left: 0;

    .gow-banner {
      height: 25vh; // Adjust the height for mobile
    }

    .gow-body {
      flex-direction: column; // Stack the info and media on top of each other
      align-items: center; // Center-align children

      .gow-info {
        width: 100%;
        padding: 10px;
      }

      .project-media {
        width: 100%;
        padding: 0;
        align-items: center; // Center-align media elements

        .gow-video {
          // Keep the aspect ratio for the video
          padding-bottom: 56.25%; // 16:9 aspect ratio
        }

        .gow-platform-logos {
          flex-direction: row; // Logos side by side
          justify-content: space-around; // Evenly distribute space around logos
          width: 100%; // Full width

          a {
            width: auto; // Auto width for anchor tags
            padding: 0 10px; // Padding around logos
          }

          img {
            width: 30%; // Adjust the size of logos for mobile
          }
        }
      }
    }
  }
}
