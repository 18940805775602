.simulation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100vw - 200px); // Sidebar width adjusted
  margin-left: 200px;
  background-color: #fff;
  height: auto; // Height auto to wrap content
  height: 100%;

  .simulation-banner {
    width: 100%;
    height: 40vh; // Keeping consistent with previous components
    overflow: hidden;

    .simulation-banner-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .simulation-body {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 20px;

    .simulation-info {
      width: 60%;
      padding: 20px;

      h1 {
        font-size: 1.5em; // Adjust font size as needed
      }

      .simulation-description {
        font-size: 0.9em;  // Adjust font size as needed
      }

      
    }

    .simulation-media {
      width: 40%;
      padding-right: 5%;

      .simulation-video {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%; // 16:9 aspect ratio
        height: 0;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      .studio-logo {
        padding-top: 20px;
        width: 300px;
        height: auto;
        margin-top: 20px;
        align-self: flex-end;
      }
    }
  }

  @media (max-width: 768px) {
    .simulation-container {
      width: 100%;
      margin-left: 0;

      .simulation-body {
        flex-direction: column;

        .simulation-info,
        .simulation-media {
          width: 100%;
          padding: 10px;
        }

        .simulation-video {
          padding-bottom: 56.25%; // Maintain aspect ratio
        }
      }
    }
  }
}
