.intro {
  display: flex;
  flex-direction: column;
  align-items: center; // This will center the children horizontally
  justify-content: flex-start; // Content starts from the top
  width: calc(100vw - 220px); // Assuming 160px is the sidebar width
  height: 100vh;
  background: #ffffff;
  text-align: center; // Center align text for name and title
  overflow: hidden; // Prevent scrolling
  margin-left: 220px; // Adjust if sidebar width changes
  position: relative;
  color: #363636;

  @media (max-width: 768px) {
    width: 100vw;
    margin-left: 0;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center; // Center align items
    width: 100%;
    padding: 2vh 5%; // Provide a bit of vertical padding, and responsive horizontal padding
    overflow: visible; // Content should fit without scrolling

    .info {
      width: 100%;
      max-width: 1200px;
      color: #363636;
      margin: 0; // Remove margins to fit within the viewport
      text-align: left; // Align text to the start for paragraphs

      h1, h2 {
        font-size: 2.2vw; // Larger font size for title
        margin: 2vh 0; // Space between title and body
      }

      p {
        font-size: 1.2vw; // Smaller font size for paragraphs
        margin: 1vh 0; // Space between paragraphs
      }
      
      .resume-button-container {
        display: flex;
        justify-content: center; // Centers the button horizontally
        margin: 2vh 0; // Space above and below the button
      }
      
      .resume-button {
        display: inline-block;
        background-color: #ffd700;
        padding: 10px 20px;
        font-size: 18px;
        text-transform: uppercase;
        text-decoration: none;
        color: black;
        border-radius: 4px;
        transition: background-color 0.3s;

        &:hover {
          background-color: #e6c300;
        }
      }
    }

    .skills-container {
      width: 100%;
      // Adjust padding and margin if necessary
      padding: 1vh 0; // Reduced vertical padding
    }
  
    .skill-column {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 5px; // Further reduced gap to make it more compact
      align-items: start;
      margin: 2px 0;
  
      @media (max-width: 768px) {
        grid-template-columns: 1fr; // Single column on smaller screens
        margin-left: 0;
      }
  
      > div { // Targeting the skill set containers directly
        h3 {
          font-size: 1.3em; // Reduce the size of 'Skills and Knowledge' heading
          margin-bottom: 10px; // Reduce space below the heading
        }
         h4 {
        font-size: 1em; // Reduced font size for the skill titles
        align-self: flex-start; // Align to the start of the flex container
        margin-top: 10px; // Reduced top margin
      }

      ul {
        padding-left: 20px; // Ensure padding for bullets
        margin-top: 5px;

        li {
          font-size: 0.8em; // Smaller font size for list items
          margin-bottom: 5px; // Less space between list items
          text-align: left; // Align text to the left
        }
      }
    }
  }
      

      .game-programming {
        h4 {
          font-size: 1em;
          margin-top: 10px; // Margin from the skills heading to the game programming heading
          // the lean on the start
          align-items: start;
        }
        ul {
          list-style-type: disc; // Adds bullet points
          margin-top: 5px;
          padding: 0; // Remove default padding

          li {
            font: 0.8em sans-serif;
            max-width: 80%; // Limit the width of the list items to 80% of the container
            margin-bottom: 2px; // Space out the list items
          }
        }
      }
      
      .vr-development {
        h4 {
          font-size: 1.1em;
          margin-top: 20px; // Margin from the game programming section to the VR development section
        }

        ul {
          list-style-type: disc; // Adds bullet points
          margin-top: 10px;
          padding: 0; // Remove default padding

          li {
            margin-bottom: 10px; // Space out the list items
          }
        }
      }

      .ai-integration{
        h4 {
          font-size: 1.1em;
          margin-top: 20px; // Margin from the VR development section to the AI integration section
        }

        ul {
          list-style-type: disc; // Adds bullet points
          margin-top: 10px;
          padding: 0; // Remove default padding

          li {
            margin-bottom: 10px; // Space out the list items
          }
        }
      }

      .Simulation-Engineer{
        h4 {
          font-size: 1.1em;
          margin-top: 20px; // Margin from the AI integration section to the Simulation Engineer section
        }

        ul {
          list-style-type: disc; // Adds bullet points
          margin-top: 10px;
          padding: 0; // Remove default padding

          li {
            margin-bottom: 10px; // Space out the list items
          }
        }
      }

      h3 {
        text-align: center; // Center the skills heading
        margin-top: 20px; // Space above the skills heading
        font-size: 1.5em;
        font-weight: 600;
      }
    }
  }


