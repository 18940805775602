.mm-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100vw - 200px); // Adjusted for consistency with the previous component
  margin-left: 200px;
  background-color: #fff;
  height: 100%;

  .mm-banner {
    width: 100%;
    height: 40vh; // Keep consistent with the previous component
    overflow: hidden;

    .mm-banner-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .mm-body {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: flex-start;

    .mm-info {
      width: 60%;
      padding: 20px;

      h1 {
        font-size: 1.5em; // Adjust font size as needed
      }

      .mm-description {
        font-size: 0.9em; // Adjust font size as needed
      }

      ul {
        font-size: 0.8em; // Adjust font size as needed
        list-style: inside square;
      }
    }

    .mm-media {
      width: 40%;
      height: 100%;
      display: flex;
      flex-direction: column;

      .mm-video {
        width: 100%;
        margin-top: 20px;
        position: relative;
        padding-bottom: 56.25%; // 16:9 aspect ratio
        height: 0;
        
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }

      .mm-platform-logos {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        a {
          margin-bottom: 10px;
        }

        img {
          width: 50%; // Adjust width as needed
          height: auto;
        }
      }

    }
  }
}

@media (max-width: 768px) {
  .mm-container {
    width: 100%;
    margin-left: 0;

    .mm-body {
      flex-direction: column;

      .mm-info {
        width: 100%;
      }

      .mm-media {
        width: 100%;
        padding: 0;

        .mm-platform-logos {
          flex-direction: row;
          justify-content: space-evenly;

          img {
            width: 30%; // Adjust for mobile layout
          }
        }
      }
    }
  }
}
