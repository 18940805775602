.sidebar {
  width: 160px;
  height: 100vh;
  background-color: #333;
  position: fixed;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  transition: width 0.3s;

  .top-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;

    .logo {
      max-height: 30vh; // max-height to ensure it doesn't overflow
      width: auto; // maintain aspect ratio
      margin-bottom: 20px;
    }

    .button {
      display: block;
      background-color: #ffd700;
      padding: 10px 20px;
      margin-bottom: 10px;
      font-size: 18px;
      text-transform: uppercase;
      text-align: center;
      text-decoration: none;
      color: #333;
      border-radius: 10px;
      transition: background-color 0.3s, color 0.3s;

      &:hover {
        background-color: #e6c300;
        color: #333;
      }
    }
  }

  .section-links {
    .section {
      display: block;
      background-color: #424242;
      padding: 15px 10px;
      color: #fff;
      font-size: 18px;
      text-transform: uppercase;
      text-align: center;
      text-decoration: none;
      border-radius: 4px;
      margin-bottom: 10px;
      transition: background-color 0.3s, color 0.3s;

      &:hover {
        background-color: #535353;
        color: #ffd700;
      }
    }
  }

  .iconContainer {
    display: flex;
    justify-content: space-around;
    margin-top: auto;
    margin-bottom: 50px;

    .icon {
      font-size: 24px;
      color: #fff;
      transition: color 0.3s;

      &:hover {
        color: #ffd700;
      }
    }
  }
}

@media (max-width: 768px) {
  .sidebar {
    display: none; /* Hide the sidebar completely on mobile devices */
  }
}
